@keyframes open {
  0% {
    opacity: 0;
    transform: scale(.5);
  }

  60% {
    opacity: 1;
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* for mobile safari */
.popover {
  opacity: 1;
}

.popover.open {
  animation: open 250ms ease-out;
  animation-fill-mode: forwards;
}

.popover-bottom.open {
  transform-origin: 50% -10px;
}

.popover-left.open {
  transform-origin: 100% 50%;
}

.dispatch-view-form {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.dispatch-view .error {
  font-size: 0.875em;
}

.dispatch-view-simple,
.dispatch-all-view {
  width: 250px;
}

.dispatch-view-simple .route {
  margin-bottom: 1em;
}

.dispatch-view-simple .route > .pure-u-1-2 {
  width: 100%;
}

.dispatch-view .items > .pure-g {
  margin-top: 0.5em;
}

.dispatch-view .pure-g > div {
  padding: 0 1em;
}

.dispatch-view .pure-g > .pure-g {
  padding: 0;
}

.dispatch-view .pure-g > div:first-child {
  padding-left: 0;
}

.dispatch-view .pure-g > div:last-child {
  padding-right: 0;
}

.dispatch-view.bottom {
  position: absolute;
}

.dispatch-view strong {
  margin: 0.25em 0;
}

.dispatch-item {
  margin: 0.5em 0;
}

.dispatch-item-row {
  align-items: center;
}

.dispatch-view-simple button,
.dispatch-all-view button {
  display: block;
  margin: 0 auto;
}

.dispatch-item-remove {
  display: block;
  line-height: 1;
  padding: 2px 5px;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .dispatch-view-content button {
    padding: 1em;
  }

  .dispatch-view-content .pure-form select {
    height: 3em;
  }

  .dispatch-view .add-transaction {
    padding: 8px 0;
  }

  .dispatch-item-remove > .ion-ios-minus-outline {
    font-size: 32px;
  }

  .dispatch-view-content .popover-close {
    padding: 18px 20px;
  }
}
