/* Dashboard Page */

.dashboard-page h2 {
  border-bottom: var(--border);
  padding-bottom: 0.25em;
}

.dashboard-page h3 {
  margin-top: 0;
  margin-bottom: 0.83em;
}

.dashboard-page .link-wrap {
  margin: 0 0 1em;
  text-align: center;
}

.dashboard-page .pure-table {
  margin-bottom: 2em;
}

.products-chart {
  height: 250px;
  margin-bottom: 1em;
}

.chart-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: hidden;
}

.chart-overlay-wrapper {
  position: relative;
}

.chart-overlay {
  height: 100%;
  width: 40px;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 125%
  );
}

.chart-empty-overlay {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  font-size: 2em;
  color: #888;
  background-color: rgba(255, 255, 255, 0.5);
  text-shadow: 0 0 10px white;
}

.ct-label {
  color: #888;
}

.ct-chart-line .ct-label.ct-horizontal.ct-end {
  max-width: 42px;
  min-width: 42px;
  min-height: 3em;
  margin-left: -21px;
  text-align: center;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.products-chart .ct-label.ct-horizontal.ct-end {
  transform: rotate(-30deg);
  transform-origin: 100% 0;
  text-align: right;
  max-height: 1.5em;
  min-width: 100px;
  max-width: 100px;
}

.ct-series .ct-bar {
  stroke: var(--highlight-green);
  stroke-width: 25px;
  transition: stroke 100ms;
}

.ct-series .ct-bar:hover {
  opacity: 0.85;
}

.ct-series .ct-bar.warn {
  stroke: var(--highlight-orange);
}

.ct-series .ct-bar.low {
  stroke: var(--highlight-red);
}

.chart-empty .ct-bar,
.chart-empty .ct-series .ct-bar.warn,
.chart-empty .ct-series .ct-bar.low {
  stroke: var(--border-light-grey);
}

.ct-series .ct-line {
  stroke: var(--highlight-green);
  stroke-width: 2px;
}

.ct-series .ct-point {
  stroke: var(--highlight-green);
  stroke-width: 6px;
  transition: stroke-width 100ms;
}

.chart-empty .ct-bar,
.chart-empty .ct-series .ct-bar.warn,
.chart-empty .ct-series .ct-bar.low,
.chart-empty .ct-series .ct-line,
.chart-empty .ct-series .ct-point {
  stroke: var(--border-light-grey);
}

.ct-series .ct-point:hover {
  stroke-width: 10px;
}

.products-per-location {
  table-layout: fixed;
}

.products-per-location th:first-child {
  width: 25%;
}

.product-locations .ct-label {
  padding: 0 2px;
}

.dashboard-page .progress {
  border: var(--border);
  background-color: white;
  overflow: hidden;
  border-radius: 3px;
  height: 8px;
  margin: 4px 0;
}

.dashboard-page .progress-bar {
  transition: width 1s ease-out;
  background-color: var(--highlight-green);
  height: 100%;
  text-align: center;
}

.dashboard-page .progress-bar.low {
  background-color: var(--highlight-red);
}

.card {
  border: var(--border);
  border-radius: 3px;
  margin-bottom: 2em;
}

.card__header {
  margin: 0;
  padding: 0.5em 1em;
  border-bottom: var(--border);
  background-color: var(--bg-light-grey);
}

.card__list {
  overflow-y: auto;
  max-height: calc(10 * 40px);
  -webkit-overflow-scrolling: touch;
  margin: 0;
}

.card__item:nth-child(even) {
  background-color: var(--bg-light-grey);
}

.card__item--name,
.card__item--value {
  display: inline-block;
  padding: 0.5em 1em;
}

.card__item--name {
  width: 66.6%;
}

.card__item--value {
  width: 33.3%;
  font-variant-numeric: tabular-nums;
  text-align: right;
}

@media only screen and (min-width: 769px) {
  .card__wrapper:nth-child(even) {
    padding-right: 1em;
  }

  .card__wrapper:nth-child(odd) {
    padding-left: 1em;
  }
}
