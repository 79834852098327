.auth-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  will-change: transform, opacity;
  align-items: center;
  justify-content: center;
  background-color: rgb(250, 250, 250);
}

.auth-container {
  position: relative;
  margin-top: -80px;
}

.auth-page .btn-back {
  position: absolute;
  opacity: 0;
}

.auth-wrap {
  -moz-perspective: 1000px;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.auth-page.flipped .auth-flip {
  transform: rotateY(180deg);
}

.auth-page.flipped .btn-back {
  opacity: 1;
}

.auth-flip {
  will-change: transform;
  transition: transform 0.35s ease-out;
  transform-style: preserve-3d;
  position: relative;
  border: var(--border);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.33);
}

.auth-flip, .front, .back {
  border-radius: 3px;
  width: 320px;
  height: 320px;
}

.front, .back {
  backface-visibility: hidden;

  position: absolute;
  top: 0;
  left: 0;
}

.front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
}

.back {
  transform: rotateY(180deg);
}

.auth-page h1 {
  text-align: center;
}

.auth-flip-view {
  padding: 15px;
  background-color: white;
}

.auth-flip-view input[data-hook=username],
.auth-flip-view input[data-hook=password] {
  margin-bottom: 1.5em;
}

.auth-flip-view .note {
  font-size: 0.75em;
  text-align: center;
}

.auth-flip-view button {
  display: block;
  margin: 0 auto;
}
