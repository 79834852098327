/* Button Styles */
.pure-button {
  transition: all 100ms;
  padding: 0.5em 1em;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  color: var(--font-color);
}

.pure-button:hover {
  box-shadow: rgba(0, 0, 0, 0.33) 0 1px 3px;
}

.pure-button[disabled]:hover {
  box-shadow: none;
}

.pure-button-primary,
.button-success,
.button-error,
.button-warning,
.button-secondary {
  color: white;
}

.pure-button-primary,
.pure-button-selected,
a.pure-button-primary,
a.pure-button-selected {
  background-color: var(--button-blue);
}

.button-success {
  background: var(--highlight-green);
}

.button-error {
  background: var(--highlight-red);
}

a.btn-round {
  color: var(--font-color);
  padding: 10px 0;
}

.btn-round {
  font-size: 1rem;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  padding: 0;
  flex-shrink: 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
}

.btn-round > i {
  vertical-align: middle;
}

.btn-round > .ion-paper-airplane {
  vertical-align: top;
}

.btn-round-small {
  font-size: 0.8125em;
  height: 28px;
  width: 28px;
  padding: 8px;
  border-radius: 14px;
  line-height: 1;
  text-align: center;
}

.btn-group {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  gap: 1em;
}

.btn-group button:first-child,
.btn-group a:first-child {
  margin-left: 0;
}

a[role='button'][disabled] {
  color: #888;
}

a.btn-back {
  display: inline-block;
  padding-right: 0.5em;
}

.btn-back:focus {
  outline: 0;
}

.btn-back:hover {
  outline: 0;
  color: #888;
  box-shadow: none;
}

.btn-back {
  background: transparent;
  border: none;
  font-size: 2em;
  transition: opacity 0.35s ease-out;
  line-height: 1.105;
  box-shadow: none;
}

/*button > i + span {
  margin-left: 5px;
}*/
