.lager .delete-storage {
  cursor: pointer;
  position: absolute;
  font-size: 1.5em;
  left: -30px;
  padding: 10px;
  opacity: 0;
}

.crash-storage {
  border-top: var(--border);
  padding-top: 25px;
}

.lager:hover .delete-storage {
  opacity: 1;
}
