/* Generated by Font Squirrel (http://www.fontsquirrel.com) on July 15, 2015 */

@font-face {
  font-family: 'Source Sans Pro';
  font-display: swap;
  src: local(''),
    url('/public/fonts/sourcesanspro-regular-webfont.woff2') format('woff2'),
    url('/public/fonts/sourcesanspro-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-display: swap;
  src: local(''),
    url('/public/fonts/sourcesanspro-bold-webfont.woff2') format('woff2'),
    url('/public/fonts/sourcesanspro-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-display: swap;
  src: local(''),
    url('/public/fonts/sourcesanspro-italic-webfont.woff2') format('woff2'),
    url('/public/fonts/sourcesanspro-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-display: swap;
  src: local(''),
    url('/public/fonts/sourcesanspro-light-webfont.woff2') format('woff2'),
    url('/public/fonts/sourcesanspro-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
