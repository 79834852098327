/* Sidebar Navigation */
.sidebar {
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* promote to own layer, improves scrolling perf */
  backface-visibility: hidden;
  top: 0;
  background-color: #222;
  height: 100%;
  width: 85px;
  padding: 25px 0 0;
}

.sidebar .dispatch {
  width: 46px;
  height: 46px;
  border-radius: 2px;
}

.sidebar a {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  width: 100%;
  text-align: center;
  color: #666;
  font-size: 1.5em;
  transition: all 0.1s ease-out;
  text-decoration: none;
  padding: 8px;
}

.sidebar a .icon {
  line-height: 0;
}

.sidebar a[disabled] {
  color: #333;
}

.sidebar .label {
  font-size: 0.75rem;
}

.sidebar .mobile-only {
  display: none;
}

.sidebar .taskboard-toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
  padding: 5px;
  margin: 0;
  font-size: 0.75em;
  color: #999;
  transition: color 0.1s ease-out;
  cursor: pointer;
  user-select: none;
}

.sidebar .taskboard-toggle:hover {
  color: white;
}

/* Firefox workaround */
.sidebar a:focus {
  outline: none;
}

.sidebar a.active,
.sidebar a:hover {
  color: #fff;
  text-shadow: 0 1px #000;
  text-decoration: none;
}

.sidebar a.active {
  background-color: #333;
}

.bottom {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
}

.sidebar .destroy {
  font-size: 1em;
}

.sidebar button {
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
}

.sync-indicator {
  background-color: var(--highlight-red);
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin-bottom: 0.5em;
}

.sync-indicator.ok {
  background-color: var(--highlight-green);
}

.sync-indicator.pending {
  background-color: orange;
}

/* Main Content */
main {
  position: relative;
  width: calc(100% - 360px - 85px);
  margin-left: 85px;
  padding: 25px 2em;
  min-height: 100vh;
}

.transit-bar-hidden main {
  width: calc(100% - 85px);
}

.create-event-form,
.event-selecltor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px;
  margin: 0 auto;
  margin-top: 1em;
}

.create-event-form h3,
.event-selector h3 {
  margin-top: 0;
}

.create-event-form form {
  border: var(--border);
  border-radius: 3px;
  padding: 1.5em;
  text-align: center;
}

.create-event-form input[type='text'] {
  margin-bottom: 1.5em;
}

@media only screen and (max-width: 1024px) {
  .sidebar {
    width: 100%;
    height: 60px;
    padding: 0 1em;
    z-index: 100;
    flex-direction: row;
  }

  .sidebar button,
  .sidebar a {
    /*display: block;*/
    margin: 0;
  }

  .sidebar .taskboard-toggle {
    display: none;
  }

  .sidebar .mobile-only {
    display: inherit;
  }

  .sidebar a {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    width: auto;
    height: 60px;
    font-size: 1.5em;
  }

  .sidebar .dispatch {
    width: 48px;
    height: 48px;
    margin-right: 1em;
  }

  .sidebar .bottom {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .sync-indicator {
    margin: 0;
  }

  body {
    padding-top: 60px;
  }

  .transit-bar-hidden main,
  main {
    width: 100%;
    margin-left: 0;
    min-height: calc(100vh - 60px);
  }
}

@media only screen and (max-width: 900px) {
  main {
    width: 100%;
    padding: 20px 1em;
  }
}
