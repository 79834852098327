.attribute-filter .popover-content {
  padding: 0;
}

.attribute-filter .popover-close {
  display: inline-block;
  position: static;
}

.attribute-filter__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.attribute-filter__header h4 {
  margin: 0;
  padding: 10px 15px;
}

.attribute-filter__list {
  margin: 0;
  max-height: 400px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.attribute-filter__item {
  cursor: pointer;
  padding: 4px 15px;
  transition: color 100ms, background-color 100ms;
  border-bottom: var(--border);
}

.attribute-filter__item:last-child {
  border-bottom-color: transparent;
}

.attribute-filter__item.selected,
.attribute-filter__item:hover {
  color: white;
  background-color: var(--highlight-blue);
}

.attribute-filter__item.selected {
  font-weight: bold;
}
