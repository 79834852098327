:root {
  --font-color: #333332;

  --bg-light-grey: #f5f5f5;
  --button-blue: rgb(0, 120, 231);
  --highlight-blue: rgb(18, 159, 234);
  --highlight-red: rgb(202, 60, 60);
  --highlight-orange: rgb(223, 117, 20);
  --highlight-green: rgb(28, 184, 65);
  --border-light-grey: #d8d8d8;
  --border: 1px solid #d8d8d8;
}
