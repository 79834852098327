@import 'npm:purecss/build/pure.css';
@import 'npm:purecss/build/grids-responsive-min.css';
@import './ionicons.css';
@import 'npm:animate.css/animate.css';
@import 'npm:popover/popover-theme.css';
@import 'npm:chartist/dist/chartist.min.css';
@import 'npm:tippy.js/dist/tippy.css';
@import './source-sans-pro.css';

@import './variables.css';
@import './layout.css';
@import './buttons.css';
@import './modal.css';

@import '../components/date-picker/date-picker.css';
@import '../components/attribute-filter/attribute-filter.css';
@import '../components/dashboard-page/dashboard-page.css';
@import '../components/auth-page/index.css';
@import '../components/app/app-view.css';
@import '../components/location-base/location.css';
@import '../components/storage-page/storage.css';
@import '../components/product-page/product.css';
@import '../components/dispatch/dispatch.css';
@import '../components/stock-taking/stock-taking.css';
