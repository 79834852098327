.pure-table,
.table {
  border-collapse: separate;
  border: var(--border);
  border-radius: 3px;
}

.pure-table thead th,
.table > header {
  font-weight: bold;
  background-color: var(--bg-light-grey);
  border-radius: 2px 2px 0 0;
  border-bottom: var(--border);
  color: var(--font-color);
}

.pure-table th:first-child {
  border-radius: 3px 0 0 0;
}

.pure-table th:last-child {
  border-radius: 0 3px 0 0;
}

.table .cell {
  padding: 0.5em 1em;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table .sort-position {
  padding: 0.5em 0;
  text-overflow: initial;
  overflow: visible;
}

.product-row {
  display: flex;
}

.table > .tbody > .pure-g > div {
  display: flex;
  align-items: center;
}

.table > .tbody > .pure-g:nth-child(even) > div {
  background-color: var(--bg-light-grey);
}

.table .action {
  justify-content: end;
}

.ion-drag {
  cursor: move;
  cursor: -webkit-grabbing;
  padding: 11px 1em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.icon.can-spill {
  display: inline-block;
  margin-left: 0.5em;
  transform: rotate(20deg);
  color: #888;
}

i.is-deleted {
  display: inline-block;
  margin-right: 0.5em;
  color: #888;
}

.item-name {
  font-weight: bold;
  white-space: normal;
}

/* Add */

.new-item .arrow,
.new-item .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.new-item .arrow {
  border-width: 11px;
}

.new-item .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: var(--border-light-grey);
  top: -11px;
}

.new-item .arrow:after {
  border-width: 10px;
  content: ' ';
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #fff;
}

.item-data-form {
  position: relative;
  padding: 1em;
  margin-top: 0.5em;
}

.new-item .item-data-form {
  border-radius: 3px;
  border: var(--border);
}

.item-data-form fieldset {
  padding-right: 1em;
}

.item-data-form label {
  font-size: 1rem;
  display: block;
  margin: 0.5em 0;
  padding-right: 2rem;
}

.item-data-form .packaging label {
  margin-bottom: 0.5em;
}

.item-data-form label > span {
  display: block;
  font-size: 0.8125em;
  margin-bottom: 0.5em;
}

.item-data-form .input-invalid > span {
  color: var(--highlight-red);
}

.item-data-form .checkbox > span {
  display: inline-block;
  margin-left: 0.5em;
}

.message {
  display: none;
}

.item-data-form input[name='name'] {
  font-weight: bold;
}

.item-data-form input[type='number'],
.item-data-form input[type='text'],
.item-data-form select {
  width: 100%;
}

.package-format label {
  margin: 0;
}

/* Edit */

.product-row-edit {
  border-top: var(--border);
  border-bottom: var(--border);
}

.product-row-edit:first-child {
  border-top: 0;
}

.product-row-edit:last-child {
  border-bottom: 0;
}

.product-row-edit .cell {
  padding: 0;
  width: 100%;
}

.table .tbody > .product-row-edit:nth-child(even) .cell {
  background-color: white;
}

.product-row-edit .item-data-form {
  margin-top: 0;
}

/* Deleted Products Section */

.deleted-products {
  margin-top: 3em;
}
