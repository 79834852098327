* {
  box-sizing: border-box;
  /* make transparent link selection, adjust last value opacity 0 to 1.0 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-size: 16px;
  line-height: 1.5;
  color: var(--font-color);
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

html,
button,
input,
select,
textarea,
.pure-g [class*='pure-u'] {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial,
    'Lucida Grande', sans-serif;
  font-weight: normal;
}

h1,
h2 {
  font-weight: 400;
  letter-spacing: -1px;
  line-height: 1.105;
  margin-top: 0;
  text-rendering: optimizeLegibility;
}

h2 > small {
  color: #888;
  font-size: 0.8125rem;
  display: block;
  line-height: 1.5;
  letter-spacing: normal;
}

h3 {
  margin-bottom: 0.25em;
}

a {
  color: var(--font-color);
  text-decoration: none;
}

a[href] {
  color: var(--button-blue);
}

a[disabled] {
  pointer-events: none;
}

a[href]:hover {
  text-decoration: underline;
}

a[role='button'] > i {
  font-size: 26px;
}

abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #888;
}

dt {
  font-size: 0.8125em;
}

/* BoozeBoy Form Styles */

label {
  font-size: 0.8125em;
  margin-bottom: 0.5em;
}

input:focus {
  outline: 0;
  border-color: var(--highlight-blue);
}

.pure-form input[type='number'],
.pure-form input[type='password'],
.pure-form input[type='text'],
.pure-form input[type='url'],
input {
  padding: 5px;
  border-radius: 0;
  border: 0;
  border-bottom: var(--border);
  box-shadow: none;
  transition: border-color 0.2s;
}

.pure-form textarea {
  padding: 5px;
  border-radius: 3px;
  border: var(--border);
  box-shadow: none;
  margin-bottom: 0.5em;
}

.select-plain,
.pure-form select {
  appearance: none;
  box-shadow: none;
  height: 2em;
  border: var(--border);
  background-color: #fff;
  color: var(--font-color);
  border-radius: 3px;
  padding: 0 5px;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2220%22%20width%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22%23d8d8d8%22%3E%3Cpath%20d%3D%22M4.516%207.548c0.436-0.446%201.043-0.481%201.576%200l3.908%203.747%203.908-3.747c0.533-0.481%201.141-0.446%201.574%200%200.436%200.445%200.408%201.197%200%201.615-0.406%200.418-4.695%204.502-4.695%204.502-0.217%200.223-0.502%200.335-0.787%200.335s-0.57-0.112-0.789-0.335c0%200-4.287-4.084-4.695-4.502s-0.436-1.17%200-1.615z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 5px top 50%;
  background-size: 20px 20px;
}

select:focus,
.pure-form select:focus {
  outline: 0;
  border-color: var(--highlight-blue);
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2220%22%20width%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22%23129fea%22%3E%3Cpath%20d%3D%22M4.516%207.548c0.436-0.446%201.043-0.481%201.576%200l3.908%203.747%203.908-3.747c0.533-0.481%201.141-0.446%201.574%200%200.436%200.445%200.408%201.197%200%201.615-0.406%200.418-4.695%204.502-4.695%204.502-0.217%200.223-0.502%200.335-0.787%200.335s-0.57-0.112-0.789-0.335c0%200-4.287-4.084-4.695-4.502s-0.436-1.17%200-1.615z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
}

.pure-form input[readonly],
.pure-form select[readonly],
.pure-form textarea[readonly] {
  background: white;
  color: inherit;
  border-bottom-color: transparent;
}

.pure-form .pure-checkbox,
.pure-form .pure-radio {
  font-size: 1rem;
}

.pure-radio input,
.pure-checkbox input {
  margin-right: 0.5em;
}

.input-addon {
  display: flex;
}

.input-addon-item {
  border-bottom: var(--border);
  line-height: 1;
  text-align: center;
  padding: 5px;
  transition: border-color 0.2s;
  background-color: #fff;
}

.input-addon-item__button {
  border: 0;
  border-bottom: var(--border);
  font-size: 1em;
}

.input-addon-item__button:focus {
  outline: 0;
}

.pure-form-stacked .input-addon-item {
  margin: 0.25em 0;
}

.pure-form input:focus:invalid,
.pure-form textarea:focus:invalid,
.pure-form select:focus:invalid {
  color: inherit;
  border-color: var(--highlight-red);
}

.input-addon-field:focus + .input-addon-item {
  border-color: var(--highlight-blue);
}

.item-data-form .invalid,
.input-addon-field:focus:invalid + .input-addon-item,
.input-addon-field.invalid + .input-addon-item {
  border-color: var(--highlight-red) !important;
}

.input-addon-field {
  flex: 1;

  /*
   * hacky workaround for firefox and flexbox
   * http://stackoverflow.com/questions/26895349/how-can-i-get-ff-33-x-flexbox-behavior-in-ff-34-x
   */
  min-width: 1px;
}

/* make lists plain */
ul {
  list-style: none;
  padding-left: 0;
}

.pure-table {
  width: 100%;
}

.pure-table,
.pure-table td,
.pure-table th {
  border: none;
}

th {
  vertical-align: top;
}

.table-striped tbody > tr:nth-child(even) > td {
  background-color: var(--bg-light-grey);
}

.append {
  display: block;
  width: 100%;
  font-size: 0.75em;
  font-weight: normal;
  color: #888;
  white-space: normal;
  letter-spacing: normal;
  line-height: normal;
}

.append a {
  color: #888;
}

a[href].error,
.error {
  color: var(--highlight-red);
}

.addon-checkmark {
  color: var(--highlight-green);
  padding-right: 1em;
  padding-left: 1em;
  border-bottom: transparent;
  opacity: 0;
}

.badge {
  display: inline-block;
  padding: 0 5px;
  font-size: 0.875em;
  font-weight: normal;
  color: var(--font-color);
  background-color: #eee;
  border-radius: 2px;
  vertical-align: middle;
}

.badge > i {
  color: #888;
  cursor: pointer;
  font-size: 12px;
  margin-left: 0.5em;
}

.badge > i:hover {
  color: var(--font-color);
}

.comment-bubble {
  line-height: 0.8125rem;
  padding: 0 0.5em;
}

.comment-bubble i {
  font-size: 22px;
}

.comment-bubble i:before {
  vertical-align: -2px;
}

/* Transaction Page */

.search-state__reset {
  font-size: 0.875em;
  color: #888;
}

.search-state__reset i {
  font-size: 0.75rem;
  margin-right: 0.5em;
}

.transaction-page__footer {
  text-align: right;
  margin-top: 0.5em;
}

.transaction-page-column__filterable {
  cursor: pointer;
}

.transaction-page-column__filterable i:before {
  vertical-align: -2px;
}

.transaction-page-column__filterable i {
  margin-left: 0.25em;
}

.transaction-list-location a {
  white-space: normal;
}

.transaction-list-item--edit > td {
  border-top: var(--border);
  border-bottom: var(--border);
  overflow: visible !important;
}

.transaction-list-item--edit .action button {
  display: block;
}

.transaction-list-item--edit .action button:first-child {
  margin-bottom: 0.5em;
}

.transaction-list-item--edit .input-units {
  max-width: 80px;
}

.transaction-list-item--edit .select-plain {
  width: 150px;
}

.transaction-page label {
  display: inline-block;
  margin-right: 0.5em;
  width: 40px;
}

.transaction-page fieldset {
  border: 0;
  margin: 0;
  padding-left: 0;
}

.transaction-page .search-bar {
  margin-right: 0;
}

.slidy {
  height: 1px;
  min-width: 32px;
  position: relative;
  background-color: var(--border-light-grey);
  margin-bottom: 1em;
}

.slidy-picker {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);

  /** Probably you want to redefine this */
  min-height: 20px;
  min-width: 20px;
  border-radius: 10px;
  background-color: #e6e6e6;
  margin-top: -10px;
}

.slidy-picker:focus {
  outline: 0;
}

.transaction-page .load-more {
  display: block;
  margin: 1em auto;
  font-size: 20px;
  width: 40px;
  text-align: center;
}

.append > i {
  padding: 0 0.5em;
}

/* Settings Page */

.settings-page .btn-group {
  white-space: nowrap;
}

.event-user-list {
  margin-top: 0;
}

.list li {
  margin-bottom: 1em;
}

.event-settings-popover {
  width: 450px;
}

.event-settings-popover h4 {
  text-align: center;
  margin: 0.5em;
}

.add-event-user-form {
  padding: 0.5em;
  border: var(--border);
  border-radius: 3px;
}

.password-form {
  margin-top: 2em;
}

/* Popover Styles */
.popover {
  position: absolute;
  border: var(--border);
  will-change: opacity, transform;
}

.popover-close,
.popover-close[href] {
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  padding: 9px 10px;
  text-align: center;
  line-height: 1;
  color: #ccc;
}

.popover-close > i,
.popover-close[href] > i {
  font-size: 1em;
}

.popover-close:hover {
  color: #888;
}

@media only screen and (max-width: 768px) {
  .pure-table td,
  .pure-table th {
    padding: 0.5em;
  }

  .ct-series .ct-bar {
    stroke-width: 15px;
  }
}
