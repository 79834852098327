.modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.33);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  animation-duration: 250ms;
}

.modal-content {
  position: relative;
  display: flex;
  padding: 30px;
  background-color: white;
  width: 650px;
  border: var(--border);
  border-radius: 3px;
  max-height: 90vh;
}

@media only screen and (max-width: 800px) {
  .modal-content {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    border: none;
    border-radius: 0;
    padding: 30px 15px;
  }
}
