.date-picker-wrapper {
  position: relative;
  /* max-width: 160px; */
}

.date-picker-wrapper input,
.date-picker-wrapper input[readonly] {
  font-size: 0.875em;
  border-bottom: var(--border);
}

.date-picker-wrapper input:focus {
  border-color: var(--border-light-grey);
}

.date-picker-wrapper:hover .input-addon-item,
.date-picker-wrapper:hover input {
  border-color: var(--highlight-blue);
  cursor: pointer;
}
