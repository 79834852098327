.stock-taking-section-header {
  display: flex;
  justify-content: space-between;
  border-bottom: var(--border);
  padding-bottom: 0.25em;
  margin: 1em 0;
}

.stock-taking-section-header button,
.stock-taking-section-header .search-bar {
  margin-right: 0;
  font-size: 1rem;
}

.btn-back,
.btn-back:hover {
  color: var(--button-blue);
  font-size: 1em;
}

.shift-end-form input[name="shiftName"] {
  width: calc(100% - 2em);
  margin-bottom: 1em;
}

/* reset some date-picker styles */
.shift-end-form .date-picker-wrapper {
  max-width: none;
  padding-right: 2em;
}

.shift-end-form .date-picker-wrapper input {
  font-size: 1em;
}

.shift-end-form .anytime-picker__dropdown {
  /* override .pure-form-stacked */
  display: inline-block;
  margin: 0 0.25em;
}

.shift-end-form .anytime-picker {
  margin-left: 0;
}

.stock-taking-page .comment-column {
  white-space: normal;
}

.stock-taking .pure-table {
  table-layout: fixed;
}

.stock-taking .pure-table th {
  width: 33%;
}

.stock-taking .pure-table th:nth-child(3),
.stock-taking .pure-table th:nth-child(4) {
  width: 16.666666667%;
}

.stock-taking td {
  vertical-align: top;
}

.stock-taking td:first-child {
  vertical-align: middle;
}

.flex {
  display: flex;
}

.stock-taking .weight-heading {
  display: flex;
  align-items: center;
  margin: 0.5 0 0.5em;
  font-size: 0.875em;
  font-weight: 700;
}

.stock-taking .right {
  text-align: right;
  justify-content: flex-end;
}

.stock-taking .weight .list {
  margin: 0;
}

.stock-taking .weight .list > li {
  margin-bottom: 0.5em;
}

.stock-taking input[name="inputPackages"],
.stock-taking input[name="inputUnits"] {
  max-width: 100px;
}

.stock-taking-meta-form label {
  margin-top: 0;
}

.stock-taking-meta-form label[for=comment] {
  margin-top: 1em;
}

.stock-taking-page__details h3 {
  margin-top: 0;
}

.stock-taking-page__details .comment-field-wrap {
  margin-top: 1em;
}

.inventory-results {
  margin-top: 1em;
  padding: 1em;
  border: var(--border);
  border-radius: 3px;
  background-color: var(--bg-light-grey);
}

.inventory-results__operator {
  display: inline-block;
  min-width: 18px;
}

.inventory-results__value {
  display: inline-block;
  width: 40%;
  text-align: right;
  margin-right: 1em;
}

.inventory-results__descr {
  font-size: 0.75em;
}

.inventory-results__result .inventory-results__operator,
.inventory-results__result .inventory-results__value {
  border-top: 1px solid black;
}

.inventory-results__result .inventory-results__value {
  font-weight: bold;
}
