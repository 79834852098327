.flex-center {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cell {
  margin-left: 1em;
}

.quick-select {
  width: 150px;
}

.search-bar {
  width: 200px;
  margin-right: 1em;
}

.search-bar .input-addon-item {
  font-size: 1.25em;
  width: auto;
  border-bottom: transparent;
}

.search-bar .reset-search {
  font-size: 1rem;
  border-bottom: var(--border);
}

.search-bar .reset-search > a > i {
  font-size: 1rem;
  color: #ccc;
}

.search-bar .reset-search > a:hover > i {
  color: #888;
}

.buttons {
  justify-content: end;
}

.location .buttons {
  font-size: 1.5em;
  margin: 0.83em 0;
}

.location {
  margin-bottom: 2em;
  transition: margin-bottom 0.25s;
}

.location .delete-location {
  opacity: 0;
  transition: opacity 0.25s;
}

.location h2 {
  margin-top: 0.83em;
}

.location--edit h2 {
  display: inline-block;
}

.location--edit .delete-location {
  opacity: 1;
}

.location--edit .drag-handle {
  margin-left: -1em;
}

.location--edit .item-list {
  display: none;
}

.location--edit .location {
  margin-bottom: 0;
}

.edit-location {
  transition: background-color 0.25s;
}

.location--edit .edit-location {
  background-color: var(--button-blue);
  color: #fff;
}

.location-name {
  padding: 5px;
  font-weight: 400;
  letter-spacing: -1px;
  border: none;
  border-bottom: 1px solid transparent;
  background-color: transparent;
  border-radius: 0;
  line-height: normal;
}

.location--edit .location-name:hover,
.location--edit .location-name:focus {
  box-shadow: none;
  border-bottom: 1px solid var(--highlight-blue);
}

.location--edit .location-name {
  border-bottom: var(--border);
}

.location__header form {
  display: inline-block;
  margin-left: 1.5em;
  font-size: 16px;
  letter-spacing: normal;
}

.packages,
.units {
  display: block;
}

.pure-table {
  white-space: nowrap;
}

.pure-table td,
.pure-table th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th.action,
td.action {
  overflow: visible;
  text-align: right;
}

.sortable-column {
  cursor: pointer;
}

.sortable-column span {
  margin-right: 0.5em;
}

.sortable-column i {
  display: inline-block;
  transition: transform 250ms;
  visibility: hidden;
}

.sortable-column.sorted i {
  visibility: visible;
}

.sortable-column.sorted.asc i {
  transform: rotate(180deg);
}

/* sortPosition */
.location-page .sortable-column:first-child {
  width: 20px;
  padding: 0.5em;
}

.location-page .sortable-column:first-child span {
  margin: 0;
}

.location-page .sortable-column:first-child span:last-child {
  display: none;
}

/* Bar Page */

tfoot > tr:first-child > td {
  font-weight: 700;
  border-top: var(--border);
}

/* Popovers */
.confirm-delete.fadeIn {
  animation-duration: 200ms;
}

.confirm-delete .btn-group {
  width: 100%;
  text-align: right;
  margin-top: 1em;
}

.inventory-popover {
  padding: 10px 15px;
}

.inventory-popover a {
  display: block;
  padding: 5px 0;
}

.inventory-popover .icon {
  margin-right: 0.5em;
}

@media screen and (min-width: 80em) {
  .admin .storage,
  .dispatcher .location {
    padding: 0 1em;
  }

  .admin .bar {
    width: 100%;
  }

  .admin.transit-bar-hidden .bar:nth-child(odd),
  .location:nth-child(odd) {
    padding-left: 0;
  }

  .admin.transit-bar-hidden .bar:nth-child(even),
  .location:nth-child(even) {
    padding-right: 0;
  }

  .admin .bar {
    width: 100%;
  }
}
